@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css?family=Oswald:regular,bold,italic');

@layer base {
  h1 {
    /*@apply font-bold text-3xl uppercase;*/
    @apply font-bold text-3xl;
  }
  h2 {
    /*@apply font-bold font-bold uppercase;*/
    @apply font-bold text-xl;
  }

  a {
    @apply underline text-blue-600 hover:text-blue-800 visited:text-purple-600
 }

  .top-nav-header a {
    @apply underline text-white hover:text-blue-800 visited:text-white
 }
}